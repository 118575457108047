<template>
<v-dialog v-if="duty" width="650" v-model="dialog">
  <v-card>
    <!-- tool bar -->
    <v-toolbar elevation="0">
      <div class="text-subtitle-1 font-weight-bold">新增</div>
      <v-spacer></v-spacer>
      <v-icon @click="dialog=false">mdi-close</v-icon>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container class="pa-5" fluid>
      <v-row>
        <v-col cols="6">
          <div class="text-subtitle-2"><span class="red--text">*</span>名称</div>
        </v-col>
        <v-col cols="6">
          <div class="text-subtitle-2"><span class="red--text">*</span>编码</div>
        </v-col>
      </v-row>
      <v-row class="mt-n7">
        <!-- duty name -->
        <v-col cols="6">
          <v-text-field v-model="duty.duty_name" dense outlined></v-text-field>
        </v-col>
        <!-- duty code -->
        <v-col cols="6">
          <v-text-field v-model="duty.duty_code" dense outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-n5">
        <v-col cols="6">
          <div class="text-subtitle-2">职务类别</div>
        </v-col>
        <v-col cols="6">
          <div class="text-subtitle-2">顺序号</div>
        </v-col>
      </v-row>
      <v-row class="mt-n7">
        <!-- duty type -->
        <v-col cols="6">
          <v-menu offset-y>
            <template v-slot:activator="{attrs, on}">
              <v-btn v-bind="attrs" v-on="on" block outlined color="grey" height="35" style="margin-top:6px;">
                <div class="text-caption">请选择</div>
                <v-spacer></v-spacer>
                <v-icon>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-card height="250">

            </v-card>
          </v-menu>
        </v-col>
        <!-- duty serial number -->
        <v-col cols="6">
          <v-text-field v-model="duty.duty_serial_num" dense outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-n4">
        <v-col cols="6">
          <div class="text-subtitle-2">描述</div>
        </v-col>
      </v-row>
      <!-- duty description -->
      <v-row class="mt-n7">
        <v-col cols="6">
          <v-textarea v-model="duty.duty_description" outlined></v-textarea>
        </v-col>
      </v-row>
      <v-row class="mt-n7">
        <v-divider></v-divider>
      </v-row>      
      <v-row class="d-flex pa-3">
        <v-spacer></v-spacer>
        <v-btn @click="dialog=false" class="mx-1" small>取消</v-btn>
        <v-btn @click="agree" class="mx-1" color="primary" small>保存</v-btn>
      </v-row>
    </v-container>
  </v-card>
</v-dialog>
</template>
<script>
export default {
  name: 'DutyDlg',
  props: ['duty'],
  data: () => ({
    dialog: false,
  }),
  methods: {
    open() {
      this.dialog = true;
    },
    agree() {
      this.$emit('agree');
      this.dialog = false;
    }
  }
}
</script>